import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import jobsData from "../data/data.json";
import SearchBar from "../components/SearchBar";

const Home = () => {
  const [selectedJob, setSelectedJob] = useState(null); // Default to no job selected for mobile
  const [titleFilter, setTitleFilter] = useState(""); // For filtering by title
  const [locationFilter, setLocationFilter] = useState(""); // For filtering by location
  const navigate = useNavigate();

  const handleSearch = () => {
    navigate(`/searchresult?title=${titleFilter}&location=${locationFilter}`);
  };

  return (
    <div className="bg-white-100 min-h-screen p-4">
      <div className="max-w-7xl mx-auto">
        {/* Search Section */}
        <SearchBar
          titleFilter={titleFilter}
          locationFilter={locationFilter}
          setTitleFilter={setTitleFilter}
          setLocationFilter={setLocationFilter}
          handleSearch={handleSearch}
        />

        {/* Main Content */}
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6 mt-4">
          {/* Mobile View */}
          <div className="block md:hidden">
            {selectedJob ? (
              // Show job details for mobile
              <div className="bg-white shadow rounded-lg p-4">
                <button
                  className="text-blue-600 underline mb-4"
                  onClick={() => setSelectedJob(null)}
                >
                  Back to jobs
                </button>
                <h2 className="text-lg font-bold">{selectedJob.title}</h2>
                <p className="text-gray-600">
                  <span className="text-blue-600">{selectedJob.company}</span> •{" "}
                  {selectedJob.location}
                </p>
                <p className="text-gray-800 font-medium">
                  {selectedJob.pay} - {selectedJob.type}
                </p>
                <div className="flex space-x-2 mt-4">
                  <button className="bg-blue-600 text-white font-medium px-4 py-2 rounded hover:bg-blue-700">
                    Apply now
                  </button>
                </div>
                <h3 className="text-lg font-semibold mt-6">Location</h3>
                <p className="text-gray-500 mb-4">{selectedJob.locationDetails}</p>
                <h3 className="text-lg font-semibold">Full job description</h3>
                <p className="text-gray-700">{selectedJob.description}</p>
              </div>
            ) : (
              // Show job cards for mobile
              <div className="bg-white shadow rounded-lg p-4">
                <h2 className="text-lg font-semibold mb-4">Jobs based on your activity</h2>
                {jobsData.map((job) => (
                  <div
                    key={job.id}
                    className="p-4 rounded-lg shadow-sm mb-4 cursor-pointer hover:bg-gray-100"
                    onClick={() => setSelectedJob(job)}
                  >
                    <h3 className="text-lg font-bold">{job.title}</h3>
                    <p className="text-sm text-gray-600">
                      {job.company} • {job.location}
                    </p>
                    <div className="flex flex-wrap gap-2 mt-2">
                      {job.tags.map((tag, index) => (
                        <span
                          key={index}
                          className="bg-gray-200 text-sm text-gray-700 px-2 py-1 rounded"
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                    <p className="text-sm text-gray-500 mt-3">{job.active}</p>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Desktop View */}
          <div className="hidden md:block w-full md:w-1/2 bg-white shadow rounded-lg p-4 overflow-y-auto max-h-[70vh]">
            <h2 className="text-lg font-semibold mb-4">Jobs based on your activity</h2>
            {jobsData.map((job) => (
              <div
                key={job.id}
                className={`p-4 rounded-lg shadow-sm mb-4 cursor-pointer ${
                  selectedJob?.id === job.id
                    ? "border-2 border-blue-600"
                    : "hover:bg-gray-100"
                }`}
                onClick={() => setSelectedJob(job)}
              >
                <h3 className="text-lg font-bold">{job.title}</h3>
                <p className="text-sm text-gray-600">
                  {job.company} • {job.location}
                </p>
                <div className="flex flex-wrap gap-2 mt-2">
                  {job.tags.map((tag, index) => (
                    <span
                      key={index}
                      className="bg-gray-200 text-sm text-gray-700 px-2 py-1 rounded"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
                <p className="text-sm text-gray-500 mt-3">{job.active}</p>
              </div>
            ))}
          </div>

          <div className="hidden md:block w-full md:w-1/2 bg-white shadow rounded-lg overflow-y-auto max-h-[70vh] relative">
            {selectedJob ? (
              <>
                <div className="sticky top-0 bg-white z-10 border-b px-6 py-4">
                  <h2 className="text-lg md:text-xl font-bold">{selectedJob.title}</h2>
                  <p className="text-gray-600">
                    <span className="text-blue-600">{selectedJob.company}</span> •{" "}
                    {selectedJob.location}
                  </p>
                  <p className="text-gray-800 font-medium">
                    {selectedJob.pay} - {selectedJob.type}
                  </p>
                  <div className="flex space-x-2 mt-2">
                    <button className="bg-blue-600 text-white font-medium px-4 py-2 rounded hover:bg-blue-700">
                      Apply now
                    </button>
                  </div>
                </div>
                <div className="px-6 py-4">
                  <h3 className="text-lg font-semibold mb-2">Location</h3>
                  <p className="text-gray-500 mb-4">{selectedJob.locationDetails}</p>
                  <h3 className="text-lg font-semibold mb-2">Full job description</h3>
                  <p className="text-gray-700 mb-2">{selectedJob.description}</p>
                </div>
              </>
            ) : (
              <p className="text-gray-500 text-center py-6">Select a job to view details</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
