import React from "react";

const Contact = () => (
  <div>
    <h1 className="text-3xl font-bold">Contact Us</h1>
    <p className="mt-4">Feel free to reach out with any questions.</p>
  </div>
);

export default Contact;
