import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../Images/MapleLogo.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-white text-gray-700 shadow-md border-b">
      <div className="w-full mx-0 px-0">
        <div className="flex justify-between items-center h-16">
          {/* Logo Section */}
          <div className="flex items-center space-x-4">
            <Link to="/" className="flex items-center">
              <img
                src={logo}
                alt="Logo"
                className="h-56 w-auto"
                style={{ marginLeft: 0 }} // Ensures no margin on the left
              />
            </Link>
          </div>

          {/* Center Navigation Links */}
          <div className="hidden md:flex space-x-8">
            <Link to="/" className="hover:text-blue-600 text-gray-700 text-sm font-medium">
              Home
            </Link>
            <Link to="/company-reviews" className="hover:text-blue-600 text-gray-700 text-sm font-medium">
              Company reviews
            </Link>
            <Link to="/salary-guide" className="hover:text-blue-600 text-gray-700 text-sm font-medium">
              Salary guide
            </Link>
          </div>

          {/* Right Aligned Links */}
          <div className="hidden md:flex items-center space-x-4">
            <Link to="/sign-in" className="hover:text-blue-600 text-gray-700 text-sm font-medium">
              Sign in
            </Link>
            <button className="hover:text-blue-600 text-gray-700 text-sm font-medium">En</button>
            <button className="hover:text-blue-600 text-gray-700 text-sm font-medium">Fr</button>
            <Link to="/employers" className="hover:text-blue-600 text-gray-700 text-sm font-medium">
              Employers / Post Job
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-gray-700 focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden bg-white border-t">
          <Link to="/" className="block px-4 py-2 text-gray-700 text-sm font-medium" onClick={() => setIsOpen(false)}>
            Home
          </Link>
          <Link to="/company-reviews" className="block px-4 py-2 text-gray-700 text-sm font-medium" onClick={() => setIsOpen(false)}>
            Company reviews
          </Link>
          <Link to="/salary-guide" className="block px-4 py-2 text-gray-700 text-sm font-medium" onClick={() => setIsOpen(false)}>
            Salary guide
          </Link>
          <Link to="/sign-in" className="block px-4 py-2 text-gray-700 text-sm font-medium" onClick={() => setIsOpen(false)}>
            Sign in
          </Link>
          <button className="block px-4 py-2 text-gray-700 text-sm font-medium" onClick={() => setIsOpen(false)}>
            En
          </button>
          <button className="block px-4 py-2 text-gray-700 text-sm font-medium" onClick={() => setIsOpen(false)}>
            Fr
          </button>
          <Link to="/employers" className="block px-4 py-2 text-gray-700 text-sm font-medium" onClick={() => setIsOpen(false)}>
            Employers / Post Job
          </Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
