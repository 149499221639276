import React from "react";

const About = () => (
  <div>
    <h1 className="text-3xl font-bold">About Us</h1>
    <p className="mt-4">Learn more about JobBoard and our mission.</p>
  </div>
);

export default About;
