import React, { useState } from "react";

const SearchBar = ({
  titleFilter,
  locationFilter,
  setTitleFilter,
  setLocationFilter,
  handleSearch,
  showDropdowns = false,
}) => {
  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleDropdown = (dropdown) => {
    setActiveDropdown((prev) => (prev === dropdown ? null : dropdown));
  };

  return (
    <div className="bg-white shadow rounded-lg p-4 max-w-4xl mx-auto mb-6">
      <div className="flex items-center space-x-4">
        <input
          type="text"
          placeholder="Job title, keywords, or company"
          value={titleFilter}
          onChange={(e) => setTitleFilter(e.target.value)}
          className="flex-grow border border-gray-300 rounded-md p-2 focus:outline-none"
        />
        <input
          type="text"
          placeholder="City, province, or region"
          value={locationFilter}
          onChange={(e) => setLocationFilter(e.target.value)}
          className="flex-grow border border-gray-300 rounded-md p-2 focus:outline-none"
        />
        <button
          onClick={handleSearch}
          className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700"
        >
          Find jobs
        </button>
      </div>

      {showDropdowns && (
        <div className="flex space-x-4 mt-4">
          {[
            { label: "Date posted", options: ["Last 24 hours", "Last 3 days", "Last 7 days", "Last 14 days", "Since your last visit"] },
            { label: "Remote", options: ["Remote only", "In-person", "Hybrid"] },
            { label: "Within 50 kilometers", options: ["10 km", "25 km", "50 km", "100 km"] },
            { label: "Pay", options: ["Hourly", "Salary"] },
            { label: "Job type", options: ["Full-time", "Part-time", "Contract", "Temporary"] },
            { label: "Location", options: ["Toronto", "Vancouver", "Calgary"] },
            { label: "Company", options: ["Google", "Amazon", "Microsoft"] },
            { label: "Job Language", options: ["English", "French"] },
          ].map((dropdown, index) => (
            <div key={index} className="relative">
              <button
                className="bg-gray-200 text-sm text-gray-700 px-3 py-2 rounded hover:bg-gray-300 cursor-pointer"
                onClick={() => toggleDropdown(dropdown.label)}
              >
                {dropdown.label}
              </button>
              {activeDropdown === dropdown.label && (
                <div className="absolute z-10 bg-white shadow-lg rounded-lg mt-2 w-48 border border-gray-200">
                  {dropdown.options.map((option, idx) => (
                    <div
                      key={idx}
                      className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        toggleDropdown(null); // Close dropdown after selecting
                      }}
                    >
                      {option}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
