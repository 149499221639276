import React from "react";

const Jobs = () => (
  <div>
    <h1 className="text-3xl font-bold">Available Jobs</h1>
    <p className="mt-4">Browse job postings below.</p>
  </div>
);

export default Jobs;
